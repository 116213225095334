// Base React modules.
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * Represents the page transition wrapper which ensures the page is scrolled
 * back to the top of the viewport upon React Router route changes.
 *
 * @class ScrollToTop
 * @extends {Component}
 * @author Charles Harwood
 */
class ScrollToTop extends Component {

	/**
	 * Defines the component's properties and their PropTypes.
	 *
	 * @static
	 */
	static propTypes = {
		children: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired
	}

	/**
	 * Called immediately after the component is mounted.
	 * Setting state here will trigger re-rendering.
	 *
	 * @returns {void}
	 */
	componentDidMount() {
		this.handleBrowserScrollPosition();
	}

	/**
	 * Called immediately after updating occurs.
	 * Not called for the initial render.
	 *
	 * @param {Object} prevProps The `props` object of the component prior to updating.
	 * @param {Object} prevState The `state` object of the component prior to updating.
	 * @returns {void}
	 */
	componentDidUpdate(prevProps, prevState) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.handleBrowserScrollPosition();
		}
	}

	/**
	 * Scrolls the window instantly to default position.
	 *
	 * @returns {void}
	 */
	handleBrowserScrollPosition() {
		window.scrollTo(0, 0);
	}


	/**
	 * Render the component to the ReactDOM.
	 *
	 * @returns {JSX.Element} React Component.
	 */
	render() {
		return this.props.children
	}

}

export default withRouter(ScrollToTop);
