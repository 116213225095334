/**!
 * Gold Coast Airport
 * Customer Rewards Promotion React Application.
 * @author Charles Harwood
 */

// Polyfills for IE11.
import 'utilities/Polyfills';
// Styles.
import 'assets/scss/App.scss';
// Base React Modules.
import React from 'react';
import { render } from 'react-snapshot';
import * as serviceWorker from 'serviceWorker';
// Vendor Libraries.
import Axios from 'axios';
import Analytics from 'react-ga';
import TagManager from 'react-gtm-module';
// Main Application.
import GCARetailPromo from 'GCARetailPromo';

// Define global API defaults.
Axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;
Axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';

// Configure Google Tag Manager and Analytics.
const tagManagerID = process.env.REACT_APP_GTM_ID;
const universalAnalyticsID = process.env.REACT_APP_GA_ID;
const tagManagerArgs = {
	gtmId: tagManagerID
}
TagManager.initialize(tagManagerArgs);
Analytics.initialize(universalAnalyticsID);

// Render the React Application. `render` here will call `ReactDOMServer.renderToString` during build,
// and `ReactDOM.hydrate` in the browser to take advantage of server pre-rendering for the initial markup.
render(<GCARetailPromo />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
