// Base React Modules.
import React, { Component } from 'react';
// Reactstrap Components.
import {
	Container, Row, Col
} from 'reactstrap';
// Assets.
import GCALogo from 'assets/images/logo-gca.svg';
import WebJetLogo from 'assets/images/logo-webjet@2x.png';

/**
 * Represents the page Footer component.
 * Renders a Footer block containing the GCA and sponsor logos.
 *
 * @class Footer
 * @extends {Component}
 * @author Charles Harwood
 */
class Footer extends Component {

	/**
	 * Render the component to the ReactDOM.
	 *
	 * @returns {JSX.Element} The `Footer` component markup.
	 */
	render() {
		return (
			<footer className="gca__footer">
				<Container>
					<Row>
						<Col>
							<img src={ GCALogo } alt="Gold Coast Airport" />
						</Col>
						<Col>
							<img src={ WebJetLogo } alt="WebJet" />
						</Col>
					</Row>
				</Container>
			</footer>
		);
	}

}

export default Footer;
