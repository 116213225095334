// Base React Modules.
import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Represents the Page Content component.
 * Renders the page's title and intro copy content.
 *
 * @class PageContent
 * @extends {Component}
 * @author Charles Harwood
 */
class PageContent extends Component {

	/**
	 * Defines the component's properties and their PropTypes.
	 *
	 * @static
	 */
	static propTypes = {
		title: PropTypes.string.isRequired,
		children: PropTypes.oneOfType([
			PropTypes.element,
			PropTypes.arrayOf(PropTypes.element)
		])
	}

	/**
	 * Render the component to the ReactDOM.
	 *
	 * @returns {JSX.Element} The `PageContent` component markup.
	 */
	render() {
		return (
			<section className="gca__content" aria-label={ this.props.title }>
				<h1 className="gca__content__title">{ this.props.title }</h1>
				{ this.props.children }
			</section>
		);
	}

}

export default PageContent;
