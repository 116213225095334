// Base React Modules.
import React, { Component } from 'react';
// Reactstrap Components.
import { Container } from 'reactstrap';
// Custom Components.
import PageContent from 'components/PageContent';

/**
 * Represents the Closed Page layout component.
 * This layout component renders the messaging regarding the competition being closed for entries.
 *
 * @class ConsumerClosedPage
 * @extends {Component}
 * @author Charles Harwood
 */
class ConsumerClosedPage extends Component {

	/**
	 * Creates an instance of `ConsumerClosedPage`.
	 *
	 * @param {Object} props The properties of the component.
	 * @returns {ConsumerClosedPage} An instance of `ConsumerClosedPage`.
	 */
	constructor(props) {
		super(props);

		this.state = {
			isMobile: false
		}

		this.handleMobileChange = this.handleMobileChange.bind(this);
	}

	componentDidMount() {
		this.handleMobileChange();
		window.addEventListener('resize', this.handleMobileChange);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleMobileChange);
	}

	handleMobileChange() {
		this.setState({
			isMobile: window.innerWidth < 768
		})
	}

	/**
	 * Render the component to the ReactDOM.
	 *
	 * @returns {JSX.Element} The `ConsumerClosedPage` component markup.
	 */
	render() {

		return (
			<Container>
				<PageContent title="Competition closed">
					<div className="text-center">
						<p>
							{`Thank you for visiting Gold Coast Airport's Shop & Fly competition entry page.`}
							{ this.state.isMobile
								? ' '
								: <br />
							}
							Unfortunately this competition is now closed.
						</p>
						<h2>Major Prize Winner - $5,000 Webjet eGift Card</h2>
						<p>M. Mack, Victoria</p>
						<h2>Runners up - $1,000 Webjet eGift Card</h2>
						<ul className="list-unstyled">
							<li>C. Milliner, Queensland</li>
							<li>T. Dunstan Hunt, New Zealand  </li>
							<li>J. Robb, New Zealand</li>
							<li>S. Lyons, Victoria</li>
							<li>C. Phillips, Queensland</li>
						</ul>
					</div>
				</PageContent>
			</Container>
		);
	}

}

export default ConsumerClosedPage;
