
/* eslint-disable */

/**!
 * Browser polyfills for ECMAScript 6, 7, 8 and 9th edition methods.
 * @author Charles Harwood
 * @see https://tc39.github.io/ecma262/
 */

// CRA's default IE11 polyfills.
// Only contains polyfills for ES6 Stage 4 proposals,
// anything pre-stage-4 or beyond ES6 needs to be specifically polyfilled below.
import 'react-app-polyfill/ie11';

// Object.entries polyfill for IE.
// @see https://tc39.github.io/ecma262/#sec-object.entries
if (!Object.entries) {
	Object.entries = function (obj) {
		var ownProps = Object.keys(obj),
			i = ownProps.length,
			resArray = new Array(i); // preallocate the Array
		while (i--) {
			resArray[ i ] = [ ownProps[ i ], obj[ ownProps[ i ] ] ];
		}

		return resArray;
	};
}

// Array.find polyfill for IE.
// @see https://tc39.github.io/ecma262/#sec-array.prototype.find
if (!Array.prototype.find) {
	Object.defineProperty(Array.prototype, 'find', {
		value: function (predicate) {
			// 1. Let O be ? ToObject(this value).
			if (this == null) {
				throw new TypeError('"this" is null or not defined');
			}

			var o = Object(this);

			// 2. Let len be ? ToLength(? Get(O, "length")).
			var len = o.length >>> 0;

			// 3. If IsCallable(predicate) is false, throw a TypeError exception.
			if (typeof predicate !== 'function') {
				throw new TypeError('predicate must be a function');
			}

			// 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
			var thisArg = arguments[ 1 ];

			// 5. Let k be 0.
			var k = 0;

			// 6. Repeat, while k < len
			while (k < len) {
				// a. Let Pk be ! ToString(k).
				// b. Let kValue be ? Get(O, Pk).
				// c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
				// d. If testResult is true, return kValue.
				var kValue = o[ k ];
				if (predicate.call(thisArg, kValue, k, o)) {
					return kValue;
				}
				// e. Increase k by 1.
				k++;
			}

			// 7. Return undefined.
			return undefined;
		},
		configurable: true,
		writable: true
	});
}
