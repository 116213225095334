// Base React Modules.
import React, { Component } from 'react';
// Assets.
import backgroundImage from 'assets/images/pattern@2x.png';
import overlayImage from 'assets/images/mega-giveaway@2x.png';

/**
 * Represents the page Header component.
 * Renders a full-width header block with the promotion logo.
 *
 * @class Header
 * @extends {Component}
 * @author Charles Harwood
 */
class Header extends Component {

	/**
	 * Render the component to the ReactDOM.
	 *
	 * @returns {JSX.Element} The `Header` component markup.
	 */
	render() {
		return (
			<header className="gca__header" style={{ backgroundImage: `url('${backgroundImage}')` }}>
				<img src={ overlayImage } alt="Shop & Fly Mega $10,000 Giveaway" />
			</header>
		);
	}

}

export default Header;
