// Base React Modules.
import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
// Vendor Libraries.
import Helmet from 'react-helmet';
import Analytics from 'react-ga';
// Pages.
import ConsumerClosedPage from './pages/consumer/Closed';
// All other pages are removed while comp is closed.
// import ConsumerLandingPage from 'pages/consumer/Landing';
// import ConsumerSuccessPage from 'pages/consumer/Success';
// import StaffLandingPage from 'pages/staff/Landing';
// import StaffSuccessPage from 'pages/staff/Success';
// import StaffErrorPage from 'pages/staff/Error';
// Components.
import Header from 'components/Header';
import Footer from 'components/Footer';
// Utilities.
import ScrollToTop from 'utilities/ScrollToTop';

/**
 * Represents the overall Retail Promo application.
 * This component handles all routing and transitional functionality for the app.
 *
 * @class GCARetailPromo
 * @extends {Component}
 * @author Charles Harwood
 */
class GCARetailPromo extends Component {

	/**
	 * Creates an instance of `GCARetailPromo`.
	 *
	 * @param {Object} props The properties of the component.
	 * @returns {GCARetailPromo} An instance of `GCARetailPromo`.
	 */
	constructor(props) {
		super(props);

		this.state = {
			prevPageView: {}
		}
	}

	/**
	 * Dynamically send Google Analytics `pageView` events on history/location changes.
	 *
	 * @param {Object} location The `react-router` location object.
	 * @param {Object} helmet The `react-helmet` state object.
	 * @returns {void}
	 */
	maybeSendPageView(location, helmet) {
		const path = location.pathname;
		const title = helmet.title;
		const prev = this.state.prevPageView;

		// Triggering callback can run more than once at a time,
		// so we store the data in state so we can be sure pages
		// don't get multiple views triggered from one routing.
		if (path !== prev.path && title !== prev.title) {
			this.setState({
				prevPageView: {
					path: path,
					title: title
				}
			}, () => Analytics.pageview(path, [], title));
		}
	}

	/**
	 * Render the component to the ReactDOM.
	 *
	 * @returns {JSX.Element} The `GCARetailPromo` component markup.
	 */
	render() {
		return (
			<BrowserRouter>
				<Route render={ ({ location }) =>
					<TransitionGroup component="main" className="gca__main">
						<CSSTransition key={ location.key } appear={ true } exit={ false } classNames="fade" timeout={ 800 }>
							<ScrollToTop>
								<div className="gca__transition-wrapper">
									<Header />
									<Switch location={ location }>
										<Route exact path="/" render={ () =>
											<>
												<Helmet defer={ false } onChangeClientState={ (helmet) => this.maybeSendPageView(location, helmet) }>
													<title>Enter Now - Customer Rewards Promotion - Gold Coast Airport</title>
												</Helmet>
												<ConsumerClosedPage />
											</>
										} />
										{/* <Route exact path="/thanks" render={ () =>
											<>
												<Helmet defer={ false } onChangeClientState={ (helmet) => this.maybeSendPageView(location, helmet) }>
													<title>Thanks for your entry - Customer Rewards Promotion - Gold Coast Airport</title>
												</Helmet>
												<ConsumerSuccessPage />
											</>
										} />
										<Route exact path="/staff" render={ () =>
											<>
												<Helmet defer={ false } onChangeClientState={ (helmet) => this.maybeSendPageView(location, helmet) }>
													<title>Enter Now - Staff Recognition Program - Gold Coast Airport</title>
												</Helmet>
												<StaffLandingPage />
											</>
										} />
										<Route exact path="/staff/thanks" render={ () =>
											<>
												<Helmet defer={ false } onChangeClientState={ (helmet) => this.maybeSendPageView(location, helmet) }>
													<title>Thanks for nominating - Staff Recognition Program - Gold Coast Airport</title>
												</Helmet>
												<StaffSuccessPage />
											</>
										} />
										<Route exact path="/staff/error" render={ () =>
											<>
												<Helmet defer={ false } onChangeClientState={ (helmet) => this.maybeSendPageView(location, helmet) }>
													<title>Nomination Error - Staff Recognition Program - Gold Coast Airport</title>
												</Helmet>
												<StaffErrorPage {...location.state} />
											</>
										} /> */}
										<Route render={ () => <Redirect to="/" /> } />
									</Switch>
									<Footer />
								</div>
							</ScrollToTop>
						</CSSTransition>
					</TransitionGroup>
				} />
			</BrowserRouter>
		);
	}

}

export default GCARetailPromo;
